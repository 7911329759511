import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-frisco-texas.png'
import image0 from "../../images/cities/scale-model-of-metropolitan-touring-in-frisco-texas.png"
import image1 from "../../images/cities/scale-model-of-toyota-tourist-in-frisco-texas.png"
import image2 from "../../images/cities/scale-model-of-the-colony-convention-&-visitors-bureau-in-frisco-texas.png"
import image3 from "../../images/cities/scale-model-of-medical-tourism-corporation-in-frisco-texas.png"
import image4 from "../../images/cities/scale-model-of-altour-american-express-in-frisco-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Frisco'
            state='Texas'
            image={image}
            lat='33.1554'
            lon='-96.8226'
            attractions={ [{"name": "Metropolitan Touring", "vicinity": "2500 Legacy Dr, Frisco", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.100282, "lng": -96.84080890000001}, {"name": "TOYOTA TOURIST", "vicinity": "5700 Legacy Dr A-4, Plano", "types": ["travel_agency", "finance", "point_of_interest", "establishment"], "lat": 33.0773572, "lng": -96.819456}, {"name": "The Colony Convention & Visitors Bureau", "vicinity": "6900 Main St, The Colony", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.0984113, "lng": -96.89228860000003}, {"name": "Medical Tourism Corporation", "vicinity": "10658 Shire View Dr, Frisco", "types": ["dentist", "health", "point_of_interest", "establishment"], "lat": 33.183602, "lng": -96.78943199999998}, {"name": "ALTOUR American Express", "vicinity": "5700 Legacy Dr Suite A4, Plano", "types": ["travel_agency", "finance", "point_of_interest", "establishment"], "lat": 33.0774, "lng": -96.819593}] }
            attractionImages={ {"Metropolitan Touring":image0,"TOYOTA TOURIST":image1,"The Colony Convention & Visitors Bureau":image2,"Medical Tourism Corporation":image3,"ALTOUR American Express":image4,} }
       />);
  }
}